
                @import '@style/mixins';
                @import '@skillbox/front-box/dist/Functional/UiVariables/style';
              
@import 'node_modules/@skillbox/front-box/dist/Blocks/UiDisclaimer/style';

.disclaimer__picture {
  width: 48px;
  height: 48px;
}
